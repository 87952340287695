import * as React from "react"
import { FC, useState } from "react"
import styled from "styled-components"
import { darkBorderGrey, darkGrey } from "../../constants/colors"
import { screenSizes } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  margin: 20px auto 0;
  display: flex;
  align-items: center;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    margin: 20px 10px;
  }

  @media (max-width: ${screenSizes.desktop}px) {
    flex-direction: column;
  }
`

const InputContainer = styled.div`
  position: relative;
  max-width: 250px;
  width: 100%;
  margin-right: 20px;

  @media (max-width: ${screenSizes.desktop}px) {
    width: 100%;
    order: 2;
    max-width: none;
    margin: 20px;
  }

  input {
    width: 100%;
  }
`

const LettersContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;

  @media (max-width: ${screenSizes.desktop}px) {
    width: 100%;
    justify-content: center;
  }
`

const Letter = styled.a<{
  disabled: boolean
}>`
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;

  color: ${darkBorderGrey};
  ${({ disabled }) => (disabled ? `color: ${darkGrey}; pointer-events: none;` : "")}

  @media (max-width: ${screenSizes.desktop}px) {
    margin: 10px 15px;
  }
`

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

interface AZFilterProps {
  usedLetters: string[]
  onSearch: (value: string) => void
  onLetterClick: (letter: string) => void
}

const AZFilter: FC<AZFilterProps> = ({ usedLetters, onSearch, onLetterClick }): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>("")

  const handleLetterChange = value => {
    setSearchValue(value)
    onSearch(value)
  }

  const handleChange = event => {
    handleLetterChange(event.target.value)
  }

  return (
    <Container>
      <InputContainer>
        <input
          type="text"
          placeholder="Search for a brand"
          onChange={handleChange}
          value={searchValue}
        />
      </InputContainer>
      <LettersContainer>
        {letters.map(letter => (
          <Letter
            key={letter}
            onClick={() => onLetterClick(letter)}
            disabled={!usedLetters.includes(letter)}
          >
            {letter}
          </Letter>
        ))}
      </LettersContainer>
    </Container>
  )
}

export default AZFilter
